import React, { Component } from 'react'
import $ from 'jquery'
import * as firebase from "firebase/app";
import "firebase/storage";
import {resizeText} from './Helpers'
import styled from 'styled-components'
import { motion } from 'framer-motion'

import "../CSS/workdisplay.css";

export const Wrapper = styled(motion.div)`
  border-top: 1.5px solid black;
  height: 100%;
  display: flex;
  width: 100%;
  padding: 3.5%;
  flex-direction: row;
  align-items: center;

    @media only screen and (max-width: 500px){
    flex-direction: column;
    height: 515px;
  }
`

export const wrapperVariants = {
  visible: {
    height: "450px",
    transition: {
      duration: 1,
      ease: "easeInOut"
    }
  },
  hidden: {
    height: "100px",
    transition: {
      duration: 1,
      ease: "easeOut"
    }
  }
}

export const FundamentalsHeader = styled.h3`
   display: flex;
  margin-top: 1.5%;
  margin-bottom: 1.5%;
  font-size: 27px;
  transition: font-size 1s ease-out;

  ${Wrapper}:hover & {
    font-size: 40px;
  }
  @media only screen and (max-width: 850px){
    font-size: 20px;
    ${Wrapper}:hover & {
      font-size: 32px;
    }

  }

  @media only screen and (max-width: 500px){
    font-size: 24px;
    ${Wrapper}:hover & {
      font-size: 24px;
    }
  }
`

export const FundamentalsSubtitle = styled(motion.h5)`
  display: flex;
  height: auto;
  font-weight: 400;
  opacity: 0;
  line-height: 0px;
  transition: opacity 0.25s ease-out, line-height 0.5s ease-out;

  ${Wrapper}:hover & {
    line-height: 125%;
    opacity: 1;
    transition: opacity 1s ease-in-out, line-height 1s ease-in-out;
  }

  @media only screen and (max-width: 500px){
    line-height: 125%;
    opacity: 1;
  }

`

export const TextWrapper = styled(motion.div)`
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: flex-start;
  height: 100%;
  flex-direction: column;
`

export const ImageWrapper = styled(motion.div)`
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: flex-end;
  height: 0px;
  width: 100%;
  margin-left: 2%;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  transition: height 1.25s ease-in-out;

  ${Wrapper}:hover & {
    height: 350px;
    transition: height 1.25s ease-in-out;
  }
  @media only screen and (max-width: 500px){
    margin-left: 0%;
    flex: 1.5;
    height: 375px;
    margin-bottom: 7%;
  }

`



export class ProjectComponent extends Component {
         constructor(props) {
           super(props);
           this.state = {
             object: {},
             expand: true,
             imageRef: "",
             height: 0,
             fontSize: 22,
             titleSize: 34,
             isMobile: false,
             isMouseTooltipVisible: true
           };
         }

         componentDidMount = () => {
           window.addEventListener('resize', this.handleResize.bind(this))
           let mobile;
           let titleSize;
           let subtitleSize
           if (window.innerWidth <= 500) {
             mobile = true
           } else {
             mobile = false
           }
           if (new resizeText(34).value < 22) {
             titleSize = 22
           } else {
             titleSize = new resizeText(34).value
           }
           if (new resizeText(22).value < 16) {
             subtitleSize = 16
           } else {
             subtitleSize = new resizeText(22).value
           }
           this.setState({
             isMobile: mobile,
             windowSize: window.innerWidth,
             titleSize: titleSize,
             fontSize: subtitleSize
           });
         };

         componentDidUpdate = (prevProps) => {
           if (this.props.info !== prevProps.info) {
             this.setState({
               object: this.props.info,
             }, () => {
              firebase
             .storage()
             .ref()
             .child(this.state.object.imageRef)
             .getDownloadURL()
             .then((url) => {
             this.setState({
               imageRef: url.toString()
             });
            })
             });
           }
         };

         handleResize = () => {
           let isMobile;
           let titleSize;
           let subtitleSize
           if(window.innerWidth <= 500){
            isMobile = true
           } else {
             isMobile = false
           }
           if (new resizeText(34).value < 22) {
             titleSize = 22
           } else {
             titleSize = new resizeText(34).value
           }
           if (new resizeText(22).value < 16) {
             subtitleSize = 16
           } else {
             subtitleSize = new resizeText(22).value
           }
          this.setState({
            fontSize: subtitleSize,
            titleSize: titleSize,
            isMobile: isMobile,
            height: $(`#${this.state.object.id}`).outerHeight(true),
            titleHeight: $(`#${this.state.object.textId}`).innerHeight(),
          })
         }



         render() {
           return (
             <React.Fragment>
               {this.state.isMobile === true ? 
                <Wrapper 
                 variants={wrapperVariants}
                 initial="hidden"
                 whileHover="visible"
                 >
                   <TextWrapper>
                      <FundamentalsHeader >{this.state.object.title}</FundamentalsHeader>
                      <FundamentalsSubtitle>{this.state.object.text}</FundamentalsSubtitle>
                   </TextWrapper>
                    <ImageWrapper style={{backgroundImage: `url(${this.state.imageRef})`}}>
                    </ImageWrapper>
                 </Wrapper> : 
                 // Split for mobile
                 // Removed variants
                 // Mobile prop added to change everything to static
                <Wrapper>
                   <TextWrapper>
                      <FundamentalsHeader>{this.state.object.title}</FundamentalsHeader>
                      <FundamentalsSubtitle>{this.state.object.text}</FundamentalsSubtitle>
                   </TextWrapper>
                   <ImageWrapper style={{backgroundImage: `url(${this.state.imageRef})`}} mobile>
                    </ImageWrapper>
                 </Wrapper>
               }
 
             </React.Fragment>
           );
         }
       }

export default ProjectComponent

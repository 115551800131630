import React, { Component } from 'react'
import CardComponent from './CardComponent'
import styled from 'styled-components'

import {ExperienceWrapper} from './ExperienceContainer'
import '../../CSS/detail.css'

// Styled components

export const DetailTitleDiv = styled.div`
  display: flex;
  width: 100%;
  padding-bottom: 1.75%;
`

const TitleHeader = styled.h2`
  padding-top: 3.5%;
  padding-bottom: 3.5%;
`

export class StrategyContainer extends Component {
         constructor(props) {
           super(props);
           this.state = {
             informationArray: [],
             id: "",
             masterColor: "#E06647"
           };
         }

         componentDidUpdate = (prevProps) => {
           if (
             this.props.info !== prevProps.info || this.props.masterColor !== prevProps.masterColor
           ) {
             this.setState({
               informationArray: this.props.info,
               masterColor: this.props.masterColor
             });
           }
         };



         render() {
           return (
             <ExperienceWrapper>
               <DetailTitleDiv strategy>
                 <TitleHeader>Strategy.</TitleHeader>
               </DetailTitleDiv>
                 {this.state.informationArray.map(object =>
                  <CardComponent masterColor={this.state.masterColor} object={object} arr={this.state.informationArray} />)}
             </ExperienceWrapper>
           );
         }
       }

export default StrategyContainer

/*
 <div className="strategyCardContainer" style={highlightStyle}>
                <div className="content-wrapper">
                  <div className="strategyCardContent" id="strategyCardNumber">
                    <h3>{number}</h3>
                  </div>
                  <div className="strategyCardContent" id="strategyCardTitle">
                    <h4>{title}</h4>
                  </div>
                </div>
              </div> */
import React, { Component } from 'react'

import {
    ChevronCompactUp
} from "react-bootstrap-icons";

const chevronStyle = {
    color: '#fafafa'
}

export class ToTopButton extends Component {

    toTop = () => {
        document.getElementById('top').scrollIntoView({ behavior: 'smooth', block: 'center' });
    }


    render() {
        return (
            <React.Fragment>
                <button type="button" className="toTopButton" onClick={this.toTop.bind(this)}>
                    <ChevronCompactUp size={20} style={chevronStyle} />
                </button>
            </React.Fragment>
        )
    }
}

export default ToTopButton

import React, { Component } from 'react'
import VizSensor from "react-visibility-sensor";
import anime from "animejs/lib/anime.es.js";
import styled from 'styled-components'

import "../../CSS/detail.css";

// Styled components

const StrategyCardWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1;
    height: 250px;
    margin-right: ${props => props.marginRight || "7%"};
    margin-left: ${props => props.marginLeft || "7%"};
    flex-direction: column;
    background-color: #ececec;

    @media only screen and (max-width: 675px){
      height: 100px;
    }
  `

const StrategyCardComponent = styled.div`
    display: flex;
    height: 100%;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-end;
    padding: 4.5%;
    width: 100%;
    font-size: 25px;

    @media only screen and (max-width: 675px){
      font-size: 16px;
    }
`

export class CardComponent extends Component {

    constructor(props){
        super(props)

        this.state = {
            object: props.object,
            arr: props.arr,
            id: props.object.highlightedId,
            masterColor: "#E06647"
        }

    }

    componentDidUpdate = (prevProps) => {
         if(this.props.object !== prevProps.object || this.props.arr !== prevProps.arr || this.props.masterColor !== prevProps.masterColor){
           this.setState({
               object: this.props.object,
               arr: this.props.arr,
               id: this.props.object.highlightedId,
               masterColor: this.props.mastercolor
           })
            }
    }

    componentDidMount = () => {
        this.setState({
          object: this.props.object,
          arr: this.props.arr,
          id: this.props.object.highlightedId,
          masterColor: this.props.masterColor
        });
    }

         renderComponent = (object, arr) => {
             let components = [];
             let headerFragment = this.createHeaders(arr, object.highlighted);
             components.push(
               <React.Fragment>
                 <div className="strategyRowContainer">{headerFragment}</div>
                 <div className="strategyTextContainer">
                   <h3 style={{paddingBottom: '0.4375%'}}>{object.title + "."}</h3>
                   <h5 style={{lineHeight: '135%'}}>{object.text}</h5>
                 </div>
               </React.Fragment>
             );
           
           return components;
         };


         createHeaders = (arr, highlight) => {
           let header = [];
           let marginLeft = ""
           let marginRight = ""
           let id = "";
           for (let i = 0; i < arr.length; i++) {
             let obj = arr[i];
             if (highlight === obj.highlighted) {
               id = this.state.id;
             } else {
               id = "";
             }
             if(i === 0){
                marginLeft = "0%"
                marginRight = "7%"
             } else if (i === 1){
                marginLeft = "7%"
                marginRight = "7%"
             } else if (i === 2){
                marginLeft = "7%"
                marginRight = "0%"
             }
             header.push(
               <StrategyCardWrapper marginLeft={marginLeft} marginRight={marginRight} id={id}>
                 <div className="content-wrapper">
                   <StrategyCardComponent id={id}>
                     {obj.number}
                   </StrategyCardComponent>
                 </div>
               </StrategyCardWrapper>
             );
           }
           return header;
         };

         handleAnimation = (isVisible) => {
             if(isVisible){
                 anime({
                   targets: `#${this.state.id}`,
                   backgroundColor: this.state.masterColor,
                   color: '#FFFFFF',
                   easing: "easeOutQuad",
                   duration: 3500,
                 });
             }
         }

         render() {
           return (
             <VizSensor
             onChange={(isVisible) => {this.handleAnimation(isVisible)}}>
               <div>
                 {this.renderComponent(this.state.object, this.state.arr)}
               </div>
             </VizSensor>
           );
         }
       }

export default CardComponent

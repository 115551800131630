import React, { Component } from 'react'
import Navbar from "../Components/Navbar";
import styled from 'styled-components'
import "../CSS/page.css";

const wrapperDivStyle = {
    border:'1.5px solid #151612',
    padding: '1.75%'
}

const linkStyle = {
    color: '#151612',
}    


const PageWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    padding: 3.5%;
    max-width: 1600px;
    width: 100%;
    align-items: flex-start;
    `

export class Contact extends Component {
    render() {
        return (
          <div className="pageContainer">
          <Navbar id="contact"/>
          <PageWrapper>            
              <div style={wrapperDivStyle}>
                <h4>312.560.2553</h4>
              </div>
              <div style={wrapperDivStyle}>
                <a href="mailto:jameswag@usc.edu" style={linkStyle}>
                  <h4>jameswag@usc.edu</h4>
                </a>
              </div>
              <div style={wrapperDivStyle}>
                <a href="mailto:jameswagner312@gmail.com" style={linkStyle}>
                  <h4>jameswagner312@gmail.com</h4>
                </a>
              </div>
          </PageWrapper>
          </div>
        );
    }
}

export default Contact

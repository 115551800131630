import React, { Component } from 'react'
import { Link } from "react-router-dom";
import * as firebase from "firebase/app";
import "firebase/storage";

import '../../CSS/detail.css'

import {resizeText} from '../Helpers.js'
import { GradientSpan } from '../WorkDisplay';

export class TitleContainer extends Component {

    constructor(props){
        super(props)
        this.state = {
            title: props.title,
            text: props.text,
            image: props.image,
            fontSize: 106,
            color: props.color
        }
    }

    componentDidMount = () => {
      window.addEventListener('resize', this.resizeText.bind(this))
      let value;
      if (new resizeText(106).value < 56) {
        value = 56
      } else {
        value = new resizeText(106).value
      }
      this.setState({
        fontSize: value
      })
    }

    resizeText = () => {
      let value;
      if (new resizeText(106).value < 56) {
        value = 56
      } else {
        value = new resizeText(106).value
      }
      this.setState({
        fontSize: value
      })
    }

    componentDidUpdate = (prevProps) => {
      if(this.props.title !== prevProps.title || this.props.text !== prevProps.text || this.props.image !== prevProps.image || this.props.color !== prevProps.color){
        firebase.storage().ref().child(this.props.image).getDownloadURL().then((url) => {
          this.setState({
            title: this.props.title,
            text: this.props.text,
            image: url.toString(),
            color: this.props.color
          })
        }) 
      }
    }

    render() {
        return (
          <div className="detailRowContainer" id="xLarge" style={{marginTop: '0%'}}>
            <div className="row-content-wrapper">
              <div className="detailTitleOverlay">
                <Link
                  to="/works"
                  className="detailNavButton"
                  id="detailBackButton"
                >
                  <h4>Back</h4>
                </Link>
                <div className="detailNavButton" id="detailTitle" style={{fontSize: `${this.state.fontSize}px`}}>
                  <h1 style={{fontSize:`${this.state.fontSize}px`}}>
                    <GradientSpan background={`linear-gradient(180deg, rgba(0,0,0,0) 65%, ${this.state.color} 35%)`}>{this.state.title}</GradientSpan></h1>
                </div>
                {/*<div className="detailNavButton" id="detailText">
                  <h6>{this.state.text}</h6>
        </div>*/}
              </div>
              <div id="product01" style={{ backgroundImage: `url(${this.state.image})` }} />
            </div>
          </div>
        );
    }
}

export default TitleContainer

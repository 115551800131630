import React, { Component } from 'react'
import styled from 'styled-components'
import { DetailTitleDiv } from './StrategyContainer';
import { ExperienceWrapper } from './ExperienceContainer';

// Styles
const resultContainerStyle = {
  backgroundColor: '#fafafa',
  color: '#151612',
  flexDirection: 'column'
}

const ListDiv = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 50%;
  padding-bottom: 3.5%;
  

  @media only screen and (max-width: 500px){
    padding-top: 3.5%;
  }
`

const TitleHeader = styled.h2`
  padding-top: 3.5%;
`

export class ResultContainer extends Component {
         constructor(props) {
           super(props);

           this.state = {
             resultInfo: props.resultInfo,
             title: props.title
           };
         }

         componentDidUpdate = (prevProps) => {
           if (this.props.resultInfo !== prevProps.resultInfo || this.props.title !== prevProps.title) {
             this.setState({
               resultInfo: this.props.resultInfo,
               title: this.props.title
             });
           }
         };

         renderFeatureLists = (arr) => {
           let featureArray = [];
           for (let i = 1; i < arr.length; i++) {
             let object = arr[i];
             featureArray.push(
               <ListDiv>
                 <h3>
                   {object.title}
                 </h3>
                 {object.values.map((value) => (
                   <h5>{value}</h5>
                 ))}
               </ListDiv>
             );
           }
           return featureArray;
         };

         render() {
           return (
             <ExperienceWrapper>
               <DetailTitleDiv>
                  <TitleHeader>{this.state.title}</TitleHeader>
               </DetailTitleDiv>
               <div className="result-title-container">
                 <h4>{this.state.resultInfo[0]}</h4>
               </div>
               <div className="featuresContainer">
                   {this.renderFeatureLists(this.state.resultInfo)}
               </div>
             </ExperienceWrapper>
           );
         }
       }

export default ResultContainer

import React, { Component } from 'react'

import LargeImageContainer from '../DetailComponents/LargeImageContainer'

export class GalleryComponent extends Component {
         constructor(props) {
           super(props);
           this.state = {
             galleryData: {},
           };
         }

      componentDidUpdate = (prevProps) => {
          if(prevProps.object !== this.props.object){
              this.setState({
                  galleryData: this.props.object,
              })
          }
      }

         render() {
           return (
             <div className="galleryContainer">
               <div className="fundRowContainer" id="fundRowTitle">
                 <h3>{this.state.galleryData.title}</h3>
               </div>
               <div className="fundRowContainer" id="fundRowTitle">
                 <h5>{this.state.galleryData.text}</h5>
               </div>
               <div className="galleryRowContainer">
                 <div className="galleryElementContainer">
                   <LargeImageContainer gallery image={this.state.galleryData.refOne} id={this.state.galleryData.imageId} />
                 </div>
                 <div className="galleryElementContainer">
                   <LargeImageContainer gallery image={this.state.galleryData.refTwo} id={this.state.galleryData.imageId} />
                 </div>
               </div>
               <div className="galleryRowContainer">
                 <div className="galleryElementContainer">
                   <LargeImageContainer gallery
                     image={this.state.galleryData.refThree} id={this.state.galleryData.imageId}
                   />
                 </div>
                 <div className="galleryElementContainer">
                   <LargeImageContainer gallery
                     image={this.state.galleryData.refFour} id={this.state.galleryData.imageId}
                   />
                 </div>
               </div>
             </div>
           );
         }
       }

export default GalleryComponent

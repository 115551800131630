import React, { Component } from 'react'
import styled from 'styled-components'
import { ExperienceWrapper } from './ExperienceContainer'
// Styles


const BriefWrapper = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: row-reverse;
  justify-content: center;
  align-items: center;
  padding-top: 1.75%;
  padding-bottom: 1.75%;

  @media only screen and (max-width: 675px){
    padding-top: 3.5%;
    padding-bottom: 3.5%;
  }
`

const BriefTextWrapper = styled.div`
  display: flex;
  height: 100%;
  flex: 1;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: ${props => props.text ? "3.5%" : "0%"};
  padding-top: 0%;

  @media only screen and (max-width: 675px){
    padding: 3.5%;
    padding-left: 1.75%;
    padding-right: 1.75%;
  }
`

export class BriefContainer extends Component {
         constructor(props) {
           super(props);

           this.state = {
             timelineValue: [],
             typeValue: [],
             rolesValue: [],
             text: ""
           };
         }

         componentDidUpdate = (prevProps) => {
           if (
             this.props.timeline !== prevProps.timeline ||
             this.props.type !== prevProps.type || this.props.roles !== prevProps.roles || this.props.text !== prevProps.text
           ) {
             this.setState({
               timelineValue: this.props.timeline,
               typeValue: this.props.type,
               rolesValue: this.props.roles,
               text: this.props.text
             });
           }
         };

         render() {
           return (
             <ExperienceWrapper>
               <BriefWrapper>
               <BriefTextWrapper text>
                <h4>{this.state.text}</h4>
               </BriefTextWrapper>
                <BriefTextWrapper style={{flex: '0.617'}}>
               <div className="brief-detail-rows">
                 <div className="brief-detail-components">
                   <h4>Type</h4>
                   {this.state.typeValue.map((val) => (
                     <h5>{val}</h5>
                   ))}
                   </div>
                      <div className="brief-detail-components">
                   <h4>Timeline</h4>
                   {this.state.timelineValue.map((val) => (
                     <h5>{val}</h5>
                   ))}
                 </div> 
                </div>  
                <div className="brief-detail-rows"> 
                  <div className="brief-detail-components" style={{flexDirection: 'row'}}>
                   <h4>Roles</h4>
                   {this.state.rolesValue.map((val) => (
                     <h5>{val}</h5>
                   ))}
                 </div>
                 </div>
                </BriefTextWrapper>
               </BriefWrapper>
                 
             </ExperienceWrapper>
           );
         }
       }

export default BriefContainer

import React, { Component } from 'react'
import GalleryComponent from './GalleryComponent'
import { ColorSquare, ColorWrapper} from '../AboutDisplay'
import { TitleDiv } from '../AboutComponents/Fundamentals'
import { ExperienceWrapper, TitleHeader } from '../DetailComponents/ExperienceContainer'
import { GradientSpan } from '../WorkDisplay'

const PRIMARY = "#E06647"
const SECONDARY = "#1D773B"
const TERTIARY = "#244224"

export class PhotoGalleries extends Component {

    constructor(props){
        super(props)
        this.state = {
            galleryData: {}    
        }
    }

    componentDidUpdate = (prevProps) => {
        if (prevProps.data !== this.props.data){
            this.setState({
                galleryData: this.props.data
            })
        }
    }


    render() {
        return (
          <ExperienceWrapper style={{borderTop: '1.5px solid black'}}>
            <TitleDiv>
              <TitleHeader><GradientSpan background={"linear-gradient(180deg, rgba(0,0,0,0) 65%, #244224 35%)"}>Projects and perspectives.</GradientSpan></TitleHeader>
                <ColorWrapper>
                  <ColorSquare textColor={PRIMARY}/>
                  <ColorSquare textColor={SECONDARY}/>
                  <ColorSquare large textColor={TERTIARY}/>
              </ColorWrapper>
            </TitleDiv>
                <GalleryComponent object={this.state.galleryData.four} />
                <GalleryComponent object={this.state.galleryData.two} />
            <GalleryComponent object={this.state.galleryData.one} />
            <GalleryComponent object={this.state.galleryData.three} />
          </ExperienceWrapper>
        );
    }
}

export default PhotoGalleries

import React, { Component } from 'react'
import anime from "animejs/lib/anime.es.js";
import "firebase/storage";
import {Link} from 'react-router-dom'
import styled from 'styled-components'
import { motion } from 'framer-motion'

import "../../CSS/about.css";


// const values

const linkStyle = {
  color: "white",
  textAlign: 'left'
};

const WrapperDiv = styled.div`
  margin-right: ${props => props.marginRightValue || "3.5%"};
  margin-left: ${props => props.marginLeftValue || "3.5%"};
    background-position: center;
  background-size: cover;
  background-repeat: no-repeat;

  @media only screen and (max-width: 500px){
    margin-left: 0%;
    margin-right: 0%;
    height: 300px;
    width: 100%;
  }
`

const LinkDiv = styled.div`
  width: 100%;
  height: auto;
  padding: 2.5%;
  color: white;
`

const StrategyCardContent = styled.div`
  display: flex;
  width: 100%;
  flex: 2;
  justify-content: center;
  align-items: center;
`

const ImageContainer = styled(motion.div)`
  border: 2px solid white;
  border-radius: 1px;
  height: 80%;
  width: 80%;
  margin-top: 3%;
  margin-bottom: 1%;
  opacity: 0;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
    
/*

  ${WrapperDiv}:hover & {
    transform: scale(8,8);
    border-left: 0.5px solid #1D773B;
    border-right: 0.5px solid #1D773B;
    border-radius: 0.5px;
    opacity: 1;
  }*/
`

const OpacityWrapper = styled(motion.div)`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  background-color: ${props => props.inputColor || "rgba(224,102,71, 1)"};
  opacity: 1.0;
  justify-content: flex-end;
  transition: background-color 1s;

  :hover{
    background-color: ${props => props.inputHover || "rgba(224,102,71, 1)"};
    //background-color: ${props => props.inputHover || "rgba(150, 150, 150, 0.75)"};
  }
`

export class FundamentalCard extends Component {

    constructor(props){
        super(props)

        this.state = {
            showDetail: false,
            data: {},
            divStyle: {},
            imageURL: "",
            number: 0
        }
    }

    componentDidMount = () => {
      var revealDetail
      console.log(window.innerWidth)
      if(window.innerWidth < 650){
        revealDetail = true
      } else {
        revealDetail = false
      }
       this.setState({
                data: this.props.data,
                number: this.props.number,
                showDetail: revealDetail
            },() => {
              console.log(this.state.data)
            })
    }

    componentDidUpdate = (prevProps) => {

        if(prevProps.data !== this.props.data){
            this.setState({
                data: this.props.data,
                number: this.props.number
            })
        }
    }

  


    animateToLarge = (direction) => {
      var heightValueExpand;
      var widthValueExpand;
      var heightValueShrink;
      var widthValueShrink;
      if (direction === "expand") {
      this.setState({
        showDetail: true,
      },() => {
          if (window.innerWidth < 650) {
            heightValueExpand = "300px"
            widthValueExpand = "100%"
     
          } else {
            heightValueExpand = "500px"
            widthValueExpand = "60%"
          }
        this.expand(heightValueExpand, widthValueExpand)
      })} else if (direction === "shrink"){
        if (window.innerWidth < 650) {
          heightValueShrink = "300px"
          widthValueShrink = "100%"
        } else {
          heightValueShrink = "425px"
          widthValueShrink = "50%"
        }
        this.shrink(heightValueShrink, widthValueShrink)
      }
    }

    expand = (height, width) => {
      anime.remove(`#${this.state.data.textId}`);
      this.setState({
        showDetail: true
      }, () => {
        let animation = anime.timeline({
          targets: `#${this.state.data.id}`,
          width: width,
          height: height,
          easing: "easeOutQuad",
          duration: 1000,
        })
          .add({
            targets: "#fundCardContent",
            opacity: "1.0",
            duration: 2000,
          }).add({
              targets:`#${this.state.data.textId}`,
            // scale: 8.5,
              opacity: 1.0,
              duration: 500,
              easing: "easeOutSine"
            })
      })
      
        // animation.finished.then(() => {
        //   anime({
        //     targets:`#hello`,
        //     scale: 9,
        //     opacity: 1.0,
        //     duration: 500,
        //     easing: "easeOutSine"
        //   })
        // })
    }

     shrink = (height, width) => {
       anime.remove(`#${this.state.data.id}`);
       this.setState({
         showDetail: false
       }, () => {
          anime.timeline({
            targets:`#${this.state.data.textId}`,
           // scale: 1,
            opacity: "0.0",
            duration: 200,
            easing: "easeOutSine"
         }).add({
           targets: `#${this.state.data.id}`,
           width: width,
           height: height,
           easing: "easeOutQuad",
           duration: 1000,
         }).add({
             targets: "#fundCardContent",
             opacity: "1.0",
             duration: 2000,
           })
         })
         
      
    }

//inputColor={this.state.data.background}
    render() {
        return (
          <React.Fragment>

                  <WrapperDiv
                    style={{backgroundImage: `url(${this.state.data.imageUrl})`}}
                  marginLeftValue={(this.state.number + 1) % 2 === 0 ? "1.5%" : "0%"}
                  marginRightValue={(this.state.number + 1) % 2 === 1 ? "1.5%" : "0%"}
                className="fundCardContainer"
                id={this.state.data.id}
                onMouseEnter={this.animateToLarge.bind(this, "expand")}
                onMouseLeave={this.animateToLarge.bind(this, "shrink")}
              >
                <OpacityWrapper inputColor={this.state.data.background} inputHover={this.state.data.backgroundHover}>
                <div className="strategyCardContent" id="fundCardTitle">
                  <h4 style={{color: 'white', fontWeight: '500'}}>
                    {this.state.data.title}
                  </h4>
                </div>
                <div className="strategyCardContent"  style={{padding: '3%', paddingTop: '1%', paddingBottom: '1%'}}>
                  <h5 style={{color: 'white'}}>{this.state.data.type}</h5>
                </div>
                {this.state.showDetail === true ? (
                  <div className="strategyCardContent" id="fundCardContent">
                    <div id={this.state.textId}>{this.state.data.description} </div>
                  </div>
                ) : null}
                <LinkDiv>                
                <Link
                  to={{
                    pathname: "/detail",
                    query: { link: this.state.data.id },
                  }}
                style={linkStyle}>
                  <h6 style={{color: 'white'}}>Go to case study →</h6>
                </Link>
                </LinkDiv>
              </OpacityWrapper>
              </WrapperDiv>

          </React.Fragment>

        );
    }
}

export default FundamentalCard

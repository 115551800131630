import React, { Component } from 'react'
import * as firebase from "firebase/app";
import "firebase/storage";
import styled from 'styled-components'

const LargeImageWrapper = styled.div`
    height: 600px;
    padding: ${props => props.gallery ? "0%" : "3.5%"};
    padding-top: 0%;
    padding-bottom: 0%;

    @media only screen and (max-width: 675px){
        height: 300px;
    }
`

export class LargeImageContainer extends Component {
    constructor(props){
        super(props)
        
        this.state = {
            loading: true,
            image: '',
            imageId: ''
        }
    }

    componentDidUpdate = (prevProps) => {
            let imageId = 'detailImage'
        if(this.props.image !== prevProps.image){
            if(this.props.id !== undefined){
                imageId = this.props.id
            }
            firebase.storage().ref().child(this.props.image).getDownloadURL().then((url) => {
                this.setState({
                    image: url.toString(),
                    loading: false,
                    imageId: imageId
                })
            }) 
        }
    }
/*

IF USED, NEED TO ADD ANIMEJS BACK
    scaleAnimation = () => {
        anime({
            targets: `#${this.state.imageId}`,
            scale: 1.0,
            easing: 'easeOutCubic',
            duration: 400
        });
    }

    scaleBack = () => {
        anime({
            targets: `#${this.state.imageId}`,
            scale: 1.0,
            easing: 'easeOutCubic',
            duration: 300
        });
    }
*/

    render() {
        return (
            <LargeImageWrapper className="detailRowContainer">
                <div className="detailImageContainer" id={this.state.imageId} 
                style={{backgroundImage: `url(${this.state.image})`}} />
            </LargeImageWrapper>
        )
    }
}

export default LargeImageContainer

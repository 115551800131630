import React, { Component } from 'react'
import axios from 'axios'
import * as firebase from "firebase/app";
import "firebase/storage";
import firebaseConfig from "../Components/firebaseConfig";
import styled from 'styled-components'

// Import components
import LogoFooter from './LogoFooter'
import {sortDisplays} from './Helpers'

// Style imports
import '../CSS/workdisplay.css'
import LoadOverlay from './LoadOverlay';
import FundamentalCard from './AboutComponents/FundamentalCard';

firebase.initializeApp(firebaseConfig);
const storage = firebase.storage();

const linkStyle = {
  textDecoration: "none",
  color: 'transparent',
  width: '100%',
  display: 'flex',
  justifyContent: "flex-start",
  backgroundColor: 'blue'
};

const images = ["01"]

const RowDiv = styled.div`
  display: flex;
  width: 100%;
  height: auto;
  flex-direction: row;
  padding: 3.5%;
  padding-bottom: 0%;
  justify-content: ${props => props.single ? "flex-start" : "center"};
  align-items: space-between;

    @media only screen and (max-width: 500px){
    flex-direction: column;
    align-items: center;
  }
`
// Stuff for quote and relevant animation

const line1 = "Beware the soul-sucking force of"
const line2 = "reasonableness. Speed bumps are"
const line3 = "reasonable. Mt. Everest is not reasonable."

const QuoteContainer = styled.div`
  display: flex;
  width: 100%;
  height: auto;
  padding-left: 3.5%;
  padding-right: 3.5%;
  padding-bottom: 2.5%;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
`

  const COLOR = "#E06647"

export const GradientSpan = styled.span`
    background: ${props => props.background || "linear-gradient(180deg, rgba(0,0,0,0) 65%, #E06647 35%)"};
    //background: linear-gradient(180deg, rgba(0,0,0,0) 65%, #E06647 35%);
    background-size: 0;
    background-repeat: no-repeat;
    transition: 0.6s ease;

    &:hover {
      background-size: 100%;
    }
`

export class WorkDisplay extends Component {
         constructor(props) {
           super(props);
           this.overlay = React.createRef()
           this.state = {
             loading: true,
             reverse: false,
             generalData: [],
             componentArray: [],
             splitArrayRows: [],
             imageNumber: images[0],
             currentImage: 0,
             isMouseTooltipVisible: false,
           };
         }

         componentDidMount = () => {
           window.scrollTo(0, 0)
           axios.get("/getProjects").then((res) => {
             this.setState(
               {
                 generalData: res.data,
               },
               () => {
                 this.getImages(this.state.generalData);
               }
             );
           });
         };

         rotateImages = () => {
           let currentImage = this.state.currentImage
           let newImage = currentImage + 1
           if (newImage >= images.length) {
             newImage = 0
           }
           this.setState({
             imageNumber: images[newImage],
             currentImage: newImage
           })
         }
         

         getImages = (arr) => {
           for (let i = 0; i < arr.length; i++) {
             let object = arr[i];
             this.createComponent(object);
           }
         };

         createComponent = (object) => {
           firebase
             .storage()
             .ref()
             .child(object.thumbnailRef)
             .getDownloadURL()
             .then((url) => {
               let projectObject = {
                 imageUrl: url.toString(),
                 id: object.id,
                 title: object.title,
                 textId: object.textId,
                 elementId: object.elementId,
                 order: object.order,
                 description: object.description,
                 type: object.type,
                 background: object.background,
                 backgroundHover: object.backgroundHover
               };
               this.setState(
                 {
                   componentArray: [
                     ...this.state.componentArray,
                     projectObject,
                   ],
                 },
                 () => {
                   if (
                     this.state.componentArray.length ===
                     this.state.generalData.length
                   ) {
                     let sortedDisplays = new sortDisplays(this.state.componentArray)
                     this.splitIntoRows(sortedDisplays);
                   }
                 }
               );
             });
         };

         signalAnimationEnd = () => {
           window.alert("Animation has ended")
         }

         splitIntoRows = (arr) => {
           this.setState(
             {
               splitArrayRows: arr,
               loading: false
             }, () => {
              this.makeFinalRows(this.state.splitArrayRows);
             }
           );
         };

         makeFinalRows = (arr) => {
           let finalRowArray = []
           let intermediateRowArray = []
           for(let i=0; i<arr.length;i++){
             let rowArr = arr[i]
             intermediateRowArray.push(
               <FundamentalCard data={rowArr} number={i} />
             )
             if(intermediateRowArray.length === 2){
              finalRowArray.push(
                <React.Fragment>
                  <RowDiv>
                  {intermediateRowArray.map((obj) =>
                    obj
                  )}
                  </RowDiv>
                </React.Fragment>
              )
              intermediateRowArray = []
             }             
           }
           if(intermediateRowArray.length !== 0){
              finalRowArray.push(
                <React.Fragment>
                  <RowDiv single>
                  {intermediateRowArray.map((obj) =>
                    obj
                  )}
                  </RowDiv>
                </React.Fragment>
              )
           }
           return finalRowArray
         }

         render() {
           return (
             <React.Fragment>
               {this.state.loading === true ? <LoadOverlay /> : null}
             <div className="displayContainer">
               <QuoteContainer>
                  <h2>
                    <GradientSpan background={"linear-gradient(180deg, rgba(0,0,0,0) 65%, #E06647 35%)"}>
                      Beware the soul-sucking force of reasonableness. Speed bumps are reasonable. Mount Everest is not.</GradientSpan></h2>
                 <br />
                 <h4><GradientSpan background={"linear-gradient(180deg, rgba(0,0,0,0) 65%, #1D773B 35%)"}>Chip and Dan Heath</GradientSpan></h4>
               </QuoteContainer>
               {/*this.state.loading === false ? <TypedText /> : null */}
               <div id="projectRowsContainer">
                 {this.makeFinalRows(this.state.splitArrayRows)}
               </div>
               <div style={{marginBottom: '2.5%'}} />
             </div>
             {this.state.loading === false ? <LogoFooter /> : null}
             </React.Fragment>
           );
         }
       }

export default WorkDisplay

/*
                 {line3.split("").map((char, index) => {
                   return(
                     <motion.span key={char + "-" + index} variants={letter}>{char}</motion.span>
                   )
                 })}
                 */
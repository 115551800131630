import React, { Component } from 'react'
import styled from 'styled-components'
import { motion } from 'framer-motion'
import * as firebase from "firebase/app";
import "firebase/storage";
import {Wrapper, wrapperVariants, FundamentalsHeader,TextWrapper, ImageWrapper} from '../ProjectComponent.js'
import { getDownload } from '../Helpers.js'

// import and create styles
import '../../CSS/detail.css'
import { DetailTitleDiv } from './StrategyContainer.js';

export const ExperienceWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: 3.5%;
  padding-right: 3.5%;
`

export const TitleHeader = styled.h2`
  padding-top: 3.5%;
  padding-bottom: 3.5%;

    @media only screen and (max-width: 500px){
      padding-top: 7%;
      padding-bottom: 7%;
    }
`

const ExperienceSubtitle = styled(motion.h4)`
  display: flex;
  height: auto;
  font-weight: 400;
  opacity: 0;
  line-height: 0px;
  padding-bottom: 0%;
  transition: opacity 0.25s ease-out, line-height 0.5s ease-out, padding-bottom 0.5s ease-out;

  ${Wrapper}:hover & {
    line-height: 125%;
    opacity: 1;
    padding-bottom: 1.5%;
    transition: opacity 1s ease-in-out, line-height 1s ease-in-out, padding-bottom 0.5s ease-in-out;
  }

  @media only screen and (max-width: 500px){
    line-height: 125%;
    padding-bottom: 1.5%;
    opacity: 1;
  }
`

const ExperienceText = styled(motion.h6)`
  display: flex;
  flex-direction: column;
  height: auto;
  justify-content: center;
  align-items: flex-start;
  font-weight: 400;
  opacity: 0;
  line-height: 0px;
  transition: opacity 0.25s ease-out, line-height 0.5s ease-out;

  ${Wrapper}:hover & {
    line-height: 125%;
    opacity: 1;
    transition: opacity 1s ease-in-out, line-height 1s ease-in-out;
  }
    @media only screen and (max-width: 500px){
    line-height: 125%;
    opacity: 1;
  }
`

export class ExperienceContainer extends Component {
         constructor(props) {
           super(props);

           this.state = {
             experienceInfo: [],
             images: [],
             currentImage: '',
             mobile: false
           };
         }

         componentDidMount = () => {
            window.addEventListener('resize', this.handleResize.bind(this))
         }

         componentDidUpdate = (prevProps) => {
           if (this.props.experienceInfo !== prevProps.experienceInfo) {
             this.setState({
               experienceInfo: this.props.experienceInfo,
             });
           }
         };


        handleResize = () => {
           let isMobile;
           if(window.innerWidth <= 500){
            isMobile = true
           } else {
             isMobile = false
           }
           this.setState({
             mobile: isMobile
           })
        }

         renderRows = (arr) => {
           let rowsToReturn = [];
           for (let i = 0; i < arr.length; i++) {
             let object = arr[i];
            let imageObject = new getDownload(object.imageRef)          
             // Code pulled from ProjectComponent and altered to include subtitle
             rowsToReturn.push(
                  <ExperienceRow info={object} image={imageObject.data}></ExperienceRow>
             );
           }
           return rowsToReturn;
         };

         render() {
           return (
             <ExperienceWrapper expansion>
               <DetailTitleDiv>
                  <TitleHeader>Experience.</TitleHeader>
               </DetailTitleDiv>
                {this.renderRows(this.state.experienceInfo)}
              </ExperienceWrapper>
           );
         }
       }

export default ExperienceContainer

class ExperienceRow extends Component {

  constructor(props){
    super(props)

    this.state = {
      object: {},
      image: ""
    }
  }

  componentDidMount = () => {
          this.setState({
        object: this.props.info,
      }, () => {
              firebase
             .storage()
             .ref()
             .child(this.state.object.imageRef)
             .getDownloadURL()
             .then((url) => {
             this.setState({
               image: url.toString()
             });
            })
             })
  }

  componentDidUpdate = (prevProps) => {
    if(this.props.info !== prevProps.info){
      this.setState({
        object: this.props.info,
      }, () => {
              firebase
             .storage()
             .ref()
             .child(this.state.object.imageRef)
             .getDownloadURL()
             .then((url) => {
             this.setState({
               image: url.toString()
             });
            })
             })
    }
  }



  render(){
    return(
      <React.Fragment>
      {this.state.isMobile === false ?       
              <Wrapper 
                 variants={wrapperVariants}
                 initial="hidden"
                 whileHover="visible"
                 >
                   <TextWrapper>
                      <FundamentalsHeader >{this.state.object.title}</FundamentalsHeader>
                      <ExperienceSubtitle>{this.state.object.subtitle}</ExperienceSubtitle>
                      <ExperienceText>{this.state.object.detail}</ExperienceText>
                   </TextWrapper>
                    <ImageWrapper style={{backgroundImage: `url(${this.state.image })`}}>
                    </ImageWrapper>
                </Wrapper> :
              <Wrapper >
                   <TextWrapper>
                      <FundamentalsHeader >{this.state.object.title}</FundamentalsHeader>
                      <ExperienceSubtitle>{this.state.object.subtitle}</ExperienceSubtitle>
                      <ExperienceText>{this.state.object.detail}</ExperienceText>
                   </TextWrapper>
                    <ImageWrapper style={{backgroundImage: `url(${this.state.image })`}} mobile>
                    </ImageWrapper>
                 </Wrapper>
        }
      </React.Fragment>

    )
  }
}
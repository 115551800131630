import React, { Component } from 'react'
import NavBar from '../Components/Navbar'
import styled from 'styled-components'
import Typing from 'react-typing-animation';

    export const PageWrapper = styled.div`
    display: flex;
    align-items: center;
    flex-direction: column;
    max-width: 1600px;
    width: 100vw;
    height: 100vh;
    `

    const string = "Hi! I'm James. I'm a student studying business, product design, and computer programming at USC. I currently work as a Customer Experience Intern at Verizon. This site is 100% designed and coded by me."


    const TextDiv = styled.div`
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: flex-end;
      align-items: flex-end;
    `

    const ContainerType = styled.div`
      width: 100%;
      height: 100%;
      min-height: 69vh;
      display: flex;
      justify-content: center;
      align-items: center;
      color: black;
      font-size: 40px;
      padding-left: 7%;
      padding-right: 7%;

        @media only screen and (min-height: 794px){
          min-height: 75vh;
        }
    `

    const BusinessText = styled.h2`
      color: #E06647;
    `
    const RegularText = styled.h3`
    line-height: 167%;
    `

    const ProductText = styled.h2`
      color: #1D773B;
    `

    const ProgrammingText = styled.h2`
      color: #244224;
    `

    const USCText = styled.h2`
      color:	#9D2235;
    `

    const CapOneFirstColor = styled.h2`
      color: #004879;
    `

    const CapOneSecondColor = styled.h2`
      color: #D22E1E;
    `

    const WrittenText = styled.h3`
      color: ${props => props.colorValue || "black"};
      transform: ${props => props.transformValue || ""};
    `

    // USED FOR 3D ANIMATION
    // let indexes = [{index: "-20", multiplier: 0},
    //           {
    //           index: "-10",
    //           multiplier: 8
    //         },
    //           {
    //           index: '-5',
    //           multiplier: 16
    //       }]

//   export function Preload({textColor, zIndex, transform}){
  
//   return (
//     <TextDiv
//     index={zIndex}>
//       <WrittenText colorValue={textColor} transformValue = {transform}>Hi! I'm James. I'm a student studying business, product design, and computer programming at USC. I currently work as a Customer Experience Intern at Verizon. This site is 100% designed and coded by me.</WrittenText>
//     </TextDiv>
//   );
// }

export class Welcome extends Component {

      constructor(props){
        super(props)

        this.state = {
          x: 30,
          y: 0
        }
      }

      componentDidMount = () => {
      }

      onMouseMove = (e) => {
          
        let valueY = (e.screenY / window.innerHeight) * 100
        let valueX = (e.screenX / window.innerWidth) * 70
/*
        if((window.innerWidth / 2) < e.screenX){
          valueX = (e.screenX / window.innerWidth) * (e.screenX / 8) - 42
        } else {
          let negScreen = e.screenX - (window.innerWidth / 2)
          valueX = (negScreen / window.innerWidth) * ((negScreen / 2) * -1)
        }
*/
        this.setState({
          x: valueX, 
          y: valueY
        }, () => {
          console.log(this.state.x)
        })
      }


  render() {
       return (
      <PageWrapper>
            <NavBar /> 
            <div style={{height: '10%'}} />
          <ContainerType>      
            <Typing speed={90}>
              <Typing.Delay ms={750} />
              <TextDiv>
                <RegularText>Hi! I'm&nbsp;</RegularText>
                <BusinessText>James.</BusinessText>
                <Typing.Delay ms={750} />
                <RegularText>&nbsp;This site is 100%&nbsp;</RegularText>
                <ProductText>designed</ProductText>
                <RegularText>&nbsp;and&nbsp;</RegularText>
                <ProgrammingText>coded</ProgrammingText>
                <RegularText>&nbsp;by me.</RegularText>
              <Typing.Delay ms={1000} />
              <Typing.Speed ms={15} />
              <Typing.Backspace count={59} />
              <Typing.Delay ms={500} />
              <Typing.Speed ms={100} />
                <RegularText>I am an&nbsp;</RegularText>
                <BusinessText>Associate</BusinessText>
                <ProductText>&nbsp;Product</ProductText>
                <ProgrammingText>&nbsp;Manager</ProgrammingText>
                <RegularText>&nbsp;at&nbsp;</RegularText>
                <CapOneFirstColor>Capital</CapOneFirstColor>
                <CapOneSecondColor>&nbsp;One.</CapOneSecondColor>
               <Typing.Delay ms={2000} />
              <Typing.Speed ms={15} />
               <Typing.Backspace count={58} />
              <Typing.Delay ms={500} />
              <Typing.Speed ms={100} />
                <RegularText>I studied&nbsp;</RegularText>
                <BusinessText>Business,</BusinessText>
                <ProductText>&nbsp;Product Design,</ProductText>
                <RegularText>&nbsp;and&nbsp;</RegularText>
                <ProgrammingText>Programming</ProgrammingText>
                <RegularText>&nbsp;at</RegularText>
                <USCText>&nbsp;USC.</USCText>
              </TextDiv>
            </Typing>
          </ContainerType>
      </PageWrapper>
    );
  }
}

// PREVIOUS PRELOAD INTERATION: 
//              <Preload textColor={`hsl(${this.state.y},71%,48%)`} zIndex={obj.index} transform={`translate(${(this.state.x / obj.multiplier)}px, ${this.state.x / obj.multiplier}px)`}/>
    //    return (
    //   <PageWrapper onMouseMove={this.onMouseMove.bind(this)}>
    //     <NavWrapper>
    //         <NavBar />
    //     </NavWrapper>
    //       {indexes.map(obj =>
    //           <Preload textColor={`hsl(${this.state.y},71%,48%)`} zIndex={obj.index}/>
    //         )}
    //   </PageWrapper>
    // );

export default Welcome
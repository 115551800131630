import React, { Component } from 'react'
import { Link } from "react-router-dom";

// Import components
import LargeImageContainer from '../DetailComponents/LargeImageContainer'


const linkStyle = {
  textDecoration: "none",
  color: "black",
};

export class Header extends Component {

    constructor(props){
        super(props)

        this.state = {
            title: '',
            text: []
        }
    }

    componentDidUpdate = (prevProps) => {
        if(prevProps.title !== this.props.title || prevProps.text !== this.props.text || prevProps.imageRef !== this.props.imageRef){
            this.setState({
                title: this.props.title,
                text: this.props.text,
                image: this.props.imageRef
            })
        }
    }

    render() {
        return (
          <div>
            <div className="headerImage">
              <LargeImageContainer image={this.state.image} />
            </div>
            <div className="headerText">
              {this.state.text.map((text) => (
                <React.Fragment>
                  <h4>{text}</h4>
                  <br />
                </React.Fragment>
              ))}
            </div>
          </div>
        );
    }
}

export default Header

import React, { Component } from 'react'
import SyntaxHighlighter from "react-syntax-highlighter";
import { vs2015 } from "react-syntax-highlighter/dist/esm/styles/hljs";

export class TechText extends Component {

    constructor(props){
        super(props)

        this.state = {
            descriptions: [],
            code: ""
        }
    }

    componentDidUpdate = (prevProps) => {
        if(prevProps.text !== this.props.text){
            let newString = this.props.text.code.split("\\n").join("\n")
            let tabString = newString.split("\\t").join("\t")
            this.setState({
                descriptions: this.props.text.descriptions,
                code: tabString
            })
        }
    }

    renderDescriptions = (arr) => {
       return (
         <React.Fragment>
           <div>
             <h4 style={{color: 'white'}}>{arr.title}</h4>
           </div>
           {arr.values.map((obj) => (
             <React.Fragment>
               <div className="tech-paragraph-wrapper">
                 {obj.descTitle}
                 <h6>{obj.descText}</h6>
               </div>
             </React.Fragment>
           ))}
         </React.Fragment>
       );
    }

    render() {
        return (
          <div className="techTextContainer">
            <div className="techTextDescription">
              {this.state.descriptions.map((subjectArr) => (
                <React.Fragment>
                  {this.renderDescriptions(subjectArr)}
                </React.Fragment>
              ))}
            </div>
            <div className="techTextImage">
              <SyntaxHighlighter language="javascript" style={vs2015} showLineNumbers={true}>
                {this.state.code}
              </SyntaxHighlighter>
            </div>
          </div>
        );
    }
}

export default TechText

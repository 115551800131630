import React, { Component } from 'react'
import Navbar from "../Components/Navbar";
import LargeImageContainer from '../Components/DetailComponents/LargeImageContainer'
import AboutDisplay from '../Components/AboutDisplay'
import ToTopButton from '../Components/ToTopButton'

// Import styles
import '../CSS/page.css'
import { PageWrapper } from './Welcome';

export class About extends Component {

    componentDidMount = () => {
        document.getElementById('top').scrollIntoView();
    }
    render() {
        return (
            <div className="pageContainer">
                <div id="top" />
                <ToTopButton />
                <Navbar id="about"/>
                <AboutDisplay />
            </div>
        )
    }
}

export default About

import React, { Component } from 'react'
import $ from 'jquery'
import TechText from './TechText'
import anime from "animejs/lib/anime.es.js";
import "../../../CSS/about.css";
import "../../../CSS/tech.css";


const ids = ["0", "1", "2", "3"]

const box = (
  <svg
    width="100%"
    height="98%"
    preserveAspectRatio="none"
    viewBox={"0 0 100 100"}
  
  >
    <path
      id="squarePath"
      stroke="#fafafa"
      fill="none"
      strokeWidth=".45"
      d={
        "M 0,0" +
        "L 100,0" +
        "L 100, 100" +
        "L 0,100" +
        "Z" //First point
        // Line across
      }
    />
  </svg>
)

var textStyle = {
  backgroundColor: "transparent",
  position: "absolute",
  left: "0%",
  top: "0%",
  width: '100%',
  height: '100%',
  zIndex: '3'
};


export class TechContainer extends Component {

    constructor(props){
        super(props)
        this.text = React.createRef()
        this.textTwo = React.createRef()
       this.textThree = React.createRef()
       this.textFour = React.createRef()

          this.state = {
            showText: false,
            animation: "",
            animationTwo: "",
            animationTriggered: false,
            info: {},
            code: [],
            descriptions: [],
          };
    }

    componentDidUpdate = (prevProps) => {
      if(this.props.info !== prevProps.info){
       this.setState({
         info: this.props.info,
       })
    }
  }

    componentDidMount = () => {
      anime({
        targets: 'path',
        strokeDashoffset: [100, 0],
        stroke: "#fafafa",
        easing: 'easeOutQuad',
        duration: 10,
      })
        var el1 = this.text.current
        var el2 = this.textTwo.current
        var el3 = this.textThree.current;
        var el4 = this.textFour.current;
          $(el1).hide();
          $(el2).hide();
          $(el3).hide();
          $(el4).hide()
    }

showAnimation = () => {
  anime.remove("path")
  var animation = anime.timeline({
    targets: 'path',
    strokeDashoffset: [100, 0],
    stroke: "#fafafa",
    easing: 'easeInQuad',
    duration: 2000,
    direction: "reverse"
  }).add({
    targets: '.techContent',
    duration: 2000
  });

  animation.finished.then(() => {
           this.setState({
             animationTriggered: true,
           });
  })
}

  hideAnimation = () => {
    if(this.state.animationTriggered === false){
      return
    }
   this.hideAllPanels()
    var animation = anime.timeline({
        targets: "path",
        strokeDashoffset: [100, 0],
        stroke: "#fafafa",
        easing: "easeOutQuad",
        duration: 2000,
      })
      .add({
        targets: ".techContent",
        borderRight: "none",
        borderLeft: "none",
        duration: 2000,
      });
  animation.finished.then(() => {
    this.setState({
      animationTriggered: false,
    });
  });

  }

  showCorrectPanel = (id) => {
    if(this.state.animationTriggered === false){
      setTimeout(this.panelSwitch.bind(this,id), 1250)
    } else {
      this.panelSwitch(id)
    }
  
  }

  panelSwitch = (id) => {
         let refToShow;
         if (id === "first") {
           refToShow = this.text.current;
         } else if (id === "second") {
           refToShow = this.textTwo.current;
         } else if (id === "third") {
           refToShow = this.textThree.current;
         } else if (id === "fourth") {
           refToShow = this.textFour.current;
         }
         this.hideAllPanels()
         $(refToShow).fadeIn(1000);
  }

  hideAllPanels = () => {
         $(this.text.current).fadeOut(400);
         $(this.textTwo.current).fadeOut(400);
         $(this.textThree.current).fadeOut(400);
         $(this.textFour.current).fadeOut(400);
  }

  renderButtons = (obj) => {
    let buttonsToRender = [];
    for(var key in obj){
      let value = obj[key]
      buttonsToRender.push(
          <button
            type="button"
            className="techNavButtons"
            onMouseEnter={this.showCorrectPanel.bind(this, key)}
          >
            <h4 style={{color: 'white'}}>{value}</h4>
          </button>
        )
      }
      return buttonsToRender
    }

    render() {

        return (
          <div className="techContainer">
            <div className="techRowContainer" id="code-title-container">
              <h2 style={{color: '#fafafa'}}>Tech</h2>
            </div>
            <div
              className="wrap-container"
              onMouseLeave={this.hideAnimation.bind(this)}
            >
              <div
                className="columnFlex techButtons"
                onMouseEnter={this.showAnimation.bind(this)}
              >
                {this.renderButtons(this.state.info.titles)}
              </div>
              <div className="columnFlex techContent">
                {box}
                <div ref={this.text} style={textStyle}>
                  <TechText text={this.state.info.first} />
                </div>
                <div ref={this.textTwo} style={textStyle}>
                  <TechText text={this.state.info.second} />
                </div>
                <div ref={this.textThree} style={textStyle}>
                  <TechText text={this.state.info.third} />
                </div>
                <div ref={this.textFour} style={textStyle}>
                  <TechText text={this.state.info.fourth} />
                </div>
              </div>
            </div>
          </div>
        );
    }
}

export default TechContainer

import React, { Component } from 'react'
import Navbar from '../Components/Navbar'

// import components
import DetailDisplay from '../Components/DetailDisplay'

// import styles
import '../CSS/detail.css'
import ToTopButton from '../Components/ToTopButton'


const detailStyle = {
  backgroundColor: "#fafafa"
}

export class Detail extends Component {

  componentDidMount = () => {
    document.getElementById('top').scrollIntoView();
  }

    render() {
        return (
          <div className="pageContainer" id="scrollId" style={detailStyle}>
            <div id="top" />
            <ToTopButton />
            <DetailDisplay
              detailId={ this.props.location.query !== undefined ? this.props.location.query.link
                  : sessionStorage.getItem("prevLocation")
              }
            />
          </div>
        );
    }
}

export default Detail

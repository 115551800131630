const firebaseConfig = {
  apiKey: "AIzaSyBe5L-OV5N6ge4_MoFn_jXgK1j7_l9Up5M",
  authDomain: "jameswagnerstudio.firebaseapp.com",
  databaseURL: "https://jameswagnerstudio.firebaseio.com",
  projectId: "jameswagnerstudio",
  storageBucket: "jameswagnerstudio.appspot.com",
  messagingSenderId: "726760051781",
  appId: "1:726760051781:web:a70b2e95c31aaea2d33f2c"
};

export default firebaseConfig;
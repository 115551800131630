import React, { Component } from 'react'
import styled from 'styled-components'
import { motion } from 'framer-motion'

    export const PageWrapper = styled.div`
    display: flex;
    position: relative;
    flex-direction: column;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0,0,0,0.5);
    `

    const imageWrapper = styled.div`
    display: flex;
    height: auto;
    width: 100vw;
    top: 0%;
    background-color: #fafafa;
    z-index: 200;

    `



export class Welcome extends Component {


  render() {
       return (
      <div className="loadOverlay">
          <div className="loadImageContainer" id="loadImage" />
      </div>
    );
  }
}

export default Welcome
import React, { Component } from 'react'
import Navbar from '../Components/Navbar'
import "../CSS/about.css";
import styled from 'styled-components'
import { GradientSpan } from '../Components/WorkDisplay';

const linkStyle = {
  color: "#151612",
};

const ResumeLinkDiv = styled.div`
  min-height: 200px;
  height: auto;
  width: 100%;
  position: fixed;
  left: 0%;
  top: 50vh;
  display: flex;
  justify-content: center;
  align-items: flex-start;

  @media only screen and (max-height: 400px){
    top: 63.5vh;
  }
`

export class Resume extends Component {

    constructor(props){
        super(props)

        this.state = {
            image: ''
        }
    }


    render() {
        return (
          <div className="pageContainer">
            <Navbar/>
              <ResumeLinkDiv>
                <a href="james-wagner-resume.pdf" target="_blank" style={linkStyle}>
                  <h4>
                    <GradientSpan>Open resume</GradientSpan></h4>
                </a>
              </ResumeLinkDiv>
              <div id="logoContainer" style={{position: "fixed", bottom: '0%'}}>
                <h4>James Wagner Studio</h4>
            </div>
          </div>
        );
    }
}

export default Resume
/*style={{position: "fixed", bottom: '0%', height: '400px', backgroundColor:"blue"}}*/
import React, { Component } from 'react'
// Component imports
import Navbar from '../Components/Navbar'
import WorkDisplay from '../Components/WorkDisplay'

// Style import
import '../CSS/works.css'
import '../CSS/page.css'
import { PageWrapper } from './Welcome'

export class Works extends Component {

    componentDidMount = () => {
        document.getElementById('top').scrollIntoView();
    }

    render() {
        return (
          <div className="pageContainer">
            <div id="top" />
            <Navbar id="works"/>
            <WorkDisplay />
          </div>
        );
    }
}

export default Works

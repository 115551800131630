import React from 'react';
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import axios from "axios";

// Import components
import Works from './Pages/Works'
import Detail from './Pages/Detail'
import About from './Pages/About'
import Resume from './Pages/Resume'
import Contact from './Pages/Contact'

import './App.css';
import Welcome from './Pages/Welcome';

axios.defaults.baseURL = "https://us-central1-jameswagnerstudio.cloudfunctions.net/api";

function App() {
  return (
    <div className="App">
      <Router>
        <Switch>
          <Route exact path="/" component={Welcome} />
          <Route exact path="/works" component={Works} />
          <Route exact path="/detail" component={Detail} />
          <Route exact path="/about" component={About} />
          <Route exact path="/resume" component={Resume} />
          <Route exact path="/contact" component={Contact} />
        </Switch>
      </Router>
    </div>
  );
}

export default App;

import React, { Component } from 'react'

import '../CSS/detail.css'

export class LogoFooter extends Component {
    render() {
        return (
            <div id="logoContainer">
                <h4>James Wagner Studio</h4>
            </div>
        )
    }
}

export default LogoFooter

import React, { Component } from 'react'
import * as firebase from "firebase/app";
import "firebase/storage";


export class Helpers extends Component {

    sortPosts = (posts) => {
    };

    render() {
        return <div></div>;
    }
}

export function getDownload(ref) {
    console.log(ref)
    firebase.storage().ref().child(ref).getDownloadURL().then((url) => {
         console.log(url.toString())
         let stringObject = {
             data: url.toString()
         }
         return stringObject
     }) 
}


export function resizeText (val){
    let scaleValue;
    if (window.innerWidth > 1280) {
        scaleValue = 1
    } else {
        scaleValue = window.innerWidth / 1280
    }
    let returnVal = {value: val * scaleValue}
    return returnVal
}

export function sortDisplays(displays) {
    let sortedDisplays = displays
        .sort((a, b) => {
            return new Date(a.order).getTime() - new Date(b.order).getTime();
        })
        .reverse();
        console.log(displays)
        console.log(sortedDisplays)
    return sortedDisplays;
}


export default Helpers




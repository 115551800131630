import React, { Component } from 'react'
import { Link } from "react-router-dom";
import { TextField, Button, MenuItem, Menu } from "@material-ui/core";

import '../CSS/navbar.css'

import {
  ChevronCompactDown
} from "react-bootstrap-icons";

const linkStyle = {
  textDecoration: "none",
};

const titleStyle = {
  textDecoration: "none",
};

const linkStyleTwo = {
    textDecoration: 'none',
    color: '#151612',
    fontSize: '25.76px'
}



export class Navbar extends Component {
    
  constructor(props) {
    super(props)
    this.state = {
      anchorEl: null,
      id: ""
    }
  }

  componentDidMount = () => {
    if(this.props.id !== undefined){
        document.getElementById(`${this.props.id}`).id = "navContact";
    }
  }

  handleClick = (event) => {
    this.setState({
      anchorEl: event.currentTarget
    })
  };

  handleClose = () => {
    this.setState({
      anchorEl: null
    })
  };

    render() {
        return (
          <div className="navBar">
            <div className="navBar-desktop">
              <div className="navLinksContainer">
                <Link to="/" style={titleStyle} className="navButtons" id="navTitle">
                James {<br />}Wagner
                </Link>
                <Link to="/works" style={linkStyle} className="navButtons" id="works">
                  works
                </Link>
                <Link to="/about" style={linkStyle} className="navButtons" id="about">
                  about
                </Link>
                <Link to="/resume" style={linkStyle} className="navButtons" id="resume">
                  resume
                </Link>
                <Link to="/contact" style={linkStyle} className="navButtons" id="contact">
                  contact
                </Link>
              </div>
            </div>
            <div className="navBar-mobile">
              <Link to="/" style={titleStyle} className="navButtons" id="navTitle">
                James{<br />}Wagner
                </Link>
              <div className="navDropdown">
                <Button
                  aria-controls="simple-menu"
                  aria-haspopup="true"
                  onClick={this.handleClick.bind(this)}
                >
                  <ChevronCompactDown size={40} />
                </Button>
                <Menu
                  id="simple-menu"
                  anchorEl={this.state.anchorEl}
                  keepMounted
                  open={Boolean(this.state.anchorEl)}
                  onClose={this.handleClose.bind(this)}
                  style={{backgroundColor: 'rgba(21,22,18,0.6)', color: "#151612"}}
                >
                  <Link to="/works" style={linkStyleTwo}>
                    <MenuItem><h4>Works</h4></MenuItem>
                  </Link>
                  <Link to="/about" style={linkStyleTwo}>
                    <MenuItem><h4>About</h4></MenuItem>
                  </Link>
                  <Link to="/resume" style={linkStyleTwo}>
                    <MenuItem><h4>Resume</h4></MenuItem>
                  </Link>
                  <Link to="/contact" style={linkStyleTwo}>
                    <MenuItem><h4>Contact</h4></MenuItem>
                  </Link>
                </Menu>
              </div>
            </div>
          </div>
        );
    }
}

export default Navbar

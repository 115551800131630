import React, { Component } from 'react'
import styled from 'styled-components'
import ProjectComponent from '../ProjectComponent'
import { ColorSquare, ColorWrapper} from '../AboutDisplay'
import { ExperienceWrapper } from '../DetailComponents/ExperienceContainer';
import { GradientSpan } from '../WorkDisplay';
import { TitleHeader } from '../DetailComponents/ExperienceContainer'

const PRIMARY = "#E06647"
const SECONDARY = "#1D773B"
const TERTIARY = "#244224"

export const TitleDiv = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
`

export class Fundamentals extends Component {

    constructor(props){
        super(props)
        
        this.state = {
            fundamentals: {},
            title: ''
        }
    }

    componentDidUpdate = (prevProps) => {
        if(prevProps.data !== this.props.data || prevProps.title !== this.props.title){
            this.setState({
                fundamentals: this.props.data,
                title: this.props.title
            },() => {
              console.log(this.state.fundamentals.five)
            })
        }
    }


    render() {
        return (
          <ExperienceWrapper>
            <TitleDiv>
              <TitleHeader>
                <GradientSpan background={"linear-gradient(180deg, rgba(0,0,0,0) 65%, #1D773B 35%)"}>Words I create by.</GradientSpan></TitleHeader>
                <ColorWrapper>
                  <ColorSquare textColor={PRIMARY}/>
                  <ColorSquare large textColor={SECONDARY}/>
                  <ColorSquare textColor={TERTIARY}/>
              </ColorWrapper>
            </TitleDiv>
            <div>
              <ProjectComponent info={this.state.fundamentals.one} reverse = {false} />
              <ProjectComponent info={this.state.fundamentals.two} reverse = {false}/>
              <ProjectComponent info={this.state.fundamentals.three} reverse = {false}/>
              {//<ProjectComponent info={this.state.fundamentals.four} reverse = {false}/>
              }
              <ProjectComponent info={this.state.fundamentals.five} reverse = {false} />
              {//<ProjectComponent info={this.state.fundamentals.six} reverse = {false}/>
              }
            </div>
          </ExperienceWrapper>
        );
    }
}

export default Fundamentals
import React, { Component } from 'react'
import {Link} from 'react-router-dom'
import axios from "axios";
import styled from 'styled-components'
import LogoFooter from './LogoFooter'

// Import functions
import {resizeText} from '../Components/Helpers'

// Import components
import LoadOverlay from "./LoadOverlay";
import Header from './AboutComponents/Header'
import Fundamentals from './AboutComponents/Fundamentals'
import PhotoGalleries from './AboutComponents/PhotoGalleries'

// Import styles
import "../CSS/about.css";
import { GradientSpan } from './WorkDisplay';

const linkStyle = {
    textDecoration: 'none',
}

const PRIMARY = "#E06647"
const SECONDARY = "#1D773B"
const TERTIARY = "#244224"

export const TitleDiv = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  padding-left: 3.5%;
  padding-right: 3.5%;
`

export const ColorSquare = styled.div`
  height: ${props => props.large ? "50px" : "30px"};
  width: ${props => props.large ? "50px" : "30px"};
  background-color: ${props => props.textColor || "#E06647"};
  margin-right: 2.5%;

  @media only screen and (max-width: 675px){
    height: ${props => props.large ? "31px" : "19px"};
    width: ${props => props.large ? "31px" : "19px"}; 
  }
`

export const ColorWrapper = styled.div`
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export class AboutDisplay extends Component {

    constructor(props){
        super(props)

        this.state = {
            headerInfo: {},
            fundamentals: {},
            loading: true,
            fontSize: 56
        }
    }

    componentDidMount = () => {
      window.addEventListener('resize', this.setFont.bind(this))
      let value;
      if (new resizeText(65).value < 34) {
        value = 34
      } else {
        value = new resizeText(65).value
      }
         axios.get("/getInfo").then((res) => {
           this.setState(
             {
               fontSize: value,
               headerInfo: res.data.header,
               fundamentals: res.data.fundamentals,
               galleries: res.data.galleries,
               loading: false
             });
         });
    }

    completedType = () => {
      console.log("type completed")
    }

    setFont = () => {
      let value;
      if (new resizeText(65).value < 34) {
        value = 34
      } else {
        value = new resizeText(65).value
      }
      this.setState({
        fontSize: value
      })
    }

    render() {
        return (
          <React.Fragment>
          <div className="displayContainer" id="aboutDisplay">
            {this.state.loading === true ? <LoadOverlay /> : null}
            <TitleDiv>
              <h2><GradientSpan background={"linear-gradient(180deg, rgba(0,0,0,0) 65%, #E06647 35%)"}>A little about me.</GradientSpan></h2>
                <ColorWrapper>
                  <ColorSquare large textColor={PRIMARY}/>
                  <ColorSquare textColor={SECONDARY}/>
                  <ColorSquare textColor={TERTIARY}/>
              </ColorWrapper>
            </TitleDiv>
            <Header
              title={this.state.headerInfo.title}
              text={this.state.headerInfo.text}
              imageRef={this.state.headerInfo.imageRef}
            />
              <div className="aboutButtonRow">
                <Link to="/contact" style={linkStyle} className="getInTouchButton">
                  <h4>Let's get in touch.</h4>
                </Link>
              </div>
            <Fundamentals
              data={this.state.fundamentals}
              title={"Words I create by:"}
            />
            <PhotoGalleries data={this.state.galleries} />
          </div>
          <LogoFooter />
          </React.Fragment>
        );
    }
}

export default AboutDisplay
